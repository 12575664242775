import React from 'react'
import { Card, Button, Container, Row, Col, Accordion } from 'react-bootstrap'
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import { Fade } from 'react-awesome-reveal'

const AssociationModule = ({ children }) => {
  const data = useStaticQuery(graphql`
    {
      allFile(filter: { absolutePath: { regex: "/association/" } }) {
        edges {
          node {
            id
            childImageSharp {
              fluid(maxWidth: 1200) {
                # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
        }
      }
    }
  `)

  const postStyle = {
    color: 'black',
    backgroundColor: 'white',
    margin: '0 auto',
    borderRadius: '25px',
    fontSize: 'calc(1rem + 0.5vw)',
  }

  const firstElement = data.allFile.edges.reverse()[0]
  const strippedArray = data.allFile.edges.slice(1)

  return (
    <Container style={postStyle} className="my-5 py-4 px-5 ">
      <h1
        style={{
          color: '#f0ad4e',
          fontFamily: 'Tangerine',
          fontSize: 'calc(1rem + 7vw)',
        }}
        className="text-center"
      >
        L' Association
      </h1>
      <p style={{ fontSize: 'calc(1rem + 0.8vw)' }}>
        <span style={{ color: '#f0ad4e' }}>
          Les amis du peintre Léon Hamonet
        </span>{' '}
        est une association loi 1901.
      </p>

      <div className="my-4">
        <Img
          fluid={firstElement.node.childImageSharp.fluid}
          alt="alt"
          className="text-center"
          style={{ borderRadius: '20px', width: '100%' }}
        />
      </div>

      <Accordion className="text-center">
        <Accordion.Toggle
          as={Button}
          variant="info"
          eventKey="0"
          size="lg"
        >
          Photos supplémentaires
        </Accordion.Toggle>

        <Accordion.Collapse eventKey="0">
          <div>
            {strippedArray.map(element => {
              return (
                <div className="my-4" key={element.node.id}>
                  <Img
                    fluid={{
                      ...element.node.childImageSharp.fluid,
                      aspectRatio: 1.6,
                    }}
                    alt="alt"
                    className=" text-center"
                    style={{ borderRadius: '20px', width: '100%' }}
                  />
                </div>
              )
            })}
          </div>
        </Accordion.Collapse>
      </Accordion>

      <Row className="my-5">
        <Col>
          <Row>
            <Col lg={3} style={{ color: '#f0ad4e' }}>
              Aout 2005
            </Col>
            <Col>Première exposition au Casino des Sables d'Or.</Col>
          </Row>
          <Row>
            <Col lg={3} style={{ color: '#f0ad4e' }}>
              Juillet 2006{' '}
            </Col>
            <Col>
              Exposition à l'Office du Tourisme d'Erquy à l'occasion de
              l'inauguration de ce bâtiment. Parution du Rowvre " Hamonet
              l'Homme et l'Oeuvre"
            </Col>
          </Row>
          <Row>
            <Col lg={3} style={{ color: '#f0ad4e' }}>
              Eté 2007
            </Col>
            <Col>Exposition à L'AteRower à la Ville Bourse.</Col>
          </Row>
          <Row>
            <Col lg={3} style={{ color: '#f0ad4e' }}>
              Octobre 2007
            </Col>
            <Col>
              Exposition à la Mairie de Rennes sur l'invitation de Monsieur
              Edmond Hervé, Maire membre d'Honneur
            </Col>
          </Row>
          <Row>
            <Col lg={3} style={{ color: '#f0ad4e' }}>
              22 Octobre 2008
            </Col>
            <Col>Décès de la Pésidente, Marie Annick Hamonet</Col>
          </Row>
          <Row>
            <Col lg={3} style={{ color: '#f0ad4e' }}>
              Aout 2009
            </Col>
            <Col>
              Assemblée Générale extraordinaire, à Erquy, avec décision de
              poursuivre la vie de l'Association. Henri Savidan et Christophe
              Deshayes sont élus Président et vice-président, tous les deux
              arrière-petits fils du Peintre.
            </Col>
          </Row>
          <Row>
            <Col lg={3} style={{ color: '#f0ad4e' }}>
              Juillet 2010
            </Col>
            <Col>Exposition à l'Office du tourisme d'Erquy.</Col>
          </Row>

          <Row>
            <Col lg={3} style={{ color: '#f0ad4e' }}>
              Avril à fin Juin 2011
            </Col>
            <Col>Exposition à la Bibliothèque de Dinan.</Col>
          </Row>
          <Row>
            <Col lg={3} style={{ color: '#f0ad4e' }}>
              Eté 2011
            </Col>
            <Col>
              Expositions à Montfort l'Amaury et à la chapelle des marins à
              Erquy.
            </Col>
          </Row>
          <Row>
            <Col lg={3} style={{ color: '#f0ad4e' }}>
              Avril 2012
            </Col>
            <Col>Expositions à Minihic-sur Rance.</Col>
          </Row>
          <Row>
            <Col lg={3} style={{ color: '#f0ad4e' }}>
              Juillet 2012
            </Col>
            <Col>Expositions à la Chapelle des Marins.</Col>
          </Row>

          <Row>
            <Col lg={3} style={{ color: '#f0ad4e' }}>
              Eté 2013
            </Col>
            <Col>Exposition à Plurien " Office du Tourisme".</Col>
          </Row>
          <Row>
            <Col lg={3} style={{ color: '#f0ad4e' }}>
              Avril 2014
            </Col>
            <Col>Exposition à Matignon.</Col>
          </Row>
          <Row>
            <Col lg={3} style={{ color: '#f0ad4e' }}>
              Octobre 2014
            </Col>
            <Col>
              Exposition à Saint-Caradec. Inauguration d'une plaque
              commémorative en hommage au peintre Place du Centre à Erquy
            </Col>
          </Row>
          <Row>
            <Col lg={3} style={{ color: '#f0ad4e' }}>
              Juin 2015
            </Col>
            <Col>
              Exposition à Erquy avec présentation des oeuvres léguées à la
              mairie, par Monsieur Alain BOTREL, ancien membre de l'association.
            </Col>
          </Row>
          <Row>
            <Col lg={3} style={{ color: '#f0ad4e' }}>
              Août 2015
            </Col>
            <Col>Exposition à Saint-Malo, au Château de la Briantais.</Col>
          </Row>
          <Row>
            <Col lg={3} style={{ color: '#f0ad4e' }}>
              Avril 2016
            </Col>
            <Col>Exposition à Saint-Briac-sur-mer.</Col>
          </Row>
          <Row>
            <Col lg={3} style={{ color: '#f0ad4e' }}>
              Avril 2016
            </Col>
            <Col>
              Exposition à la maison de la mer à ERQUY pour l'inauguration de
              l'escalier donnant accès au cap, pour le président du conseil
              général .
            </Col>
          </Row>
          <Row>
            <Col lg={3} style={{ color: '#f0ad4e' }}>
              Juillet 2016
            </Col>
            <Col>Exposition à Pléneuf val André</Col>
          </Row>
          <Row>
            <Col lg={3} style={{ color: '#f0ad4e' }}>
              Décembre 2016
            </Col>
            <Col>Exposition au marché de Noël d'Erquy</Col>
          </Row>
          <Row>
            <Col lg={3} style={{ color: '#f0ad4e' }}>
              Juillet 2017
            </Col>
            <Col>Exposition à Saint Quay Portrieux</Col>
          </Row>
          <Row>
            <Col lg={3} style={{ color: '#f0ad4e' }}>
              Septembre 2017
            </Col>
            <Col>
              Exposition au château de Bienassis dans le cadre des journées du
              patrimoine.
            </Col>
          </Row>
          <Row>
            <Col lg={3} style={{ color: '#f0ad4e' }}>
              Décembre 2017
            </Col>
            <Col>Exposition au marché de Nöel de Saint-Alban</Col>
          </Row>
          <Row>
            <Col lg={3} style={{ color: '#f0ad4e' }}>
              Décembre 2018
            </Col>
            <Col>Exposition au marché de Noël d'Erquy</Col>
          </Row>
          <Row>
            <Col lg={3} style={{ color: '#f0ad4e' }}>
              Juillet/Août 2019
            </Col>
            <Col>Exposition à ERQUY à la galerie d'art.</Col>
          </Row>
        </Col>
      </Row>
    </Container>
  )
}

export default AssociationModule
